import Constants from 'expo-constants';
import { useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import AuthenticationPage from './page/AuthenticationPage/AuthenticationPage';

Sentry.init({
  dsn: Constants.manifest?.extra?.sentryDns,
  enableInExpoDevelopment: false,
  debug: true,
  environment: Constants.manifest?.extra?.stage,
});

if (__DEV__) {
  const { connectToDevTools } = require('react-devtools-core');

  connectToDevTools({
    host: 'localhost',
    port: 8097,
  });
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  return (
    <SafeAreaProvider>
      <AuthenticationPage />
    </SafeAreaProvider>
  );

  // if (!isLoadingComplete) {
  //   return null;
  // } else {
  //   return (
  //     <SafeAreaProvider>
  //       <Navigation colorScheme={colorScheme} />
  //       <StatusBar />
  //     </SafeAreaProvider>
  //   );
  // }
}
