import { useEffect } from 'react';
import { Button, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import useAsync from 'react-use/lib/useAsync';

import useAuthentication from './useAuthentication';
import useSessionStorage from './useSessionStorage';

type AuthenticationPageProps = {};

function AuthenticationPage() {
  const { prompt, authStatus } = useAuthentication();

  const sessionStorage = useSessionStorage();

  useAsync(async () => {
    if (authStatus === 'success') {
      const token = await sessionStorage.getToken();
      console.log('from sessionStorage', token);
    }
  }, [authStatus]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ alignSelf: 'flex-end' }}>
        <Button title="LOGIN" onPress={prompt} />
      </View>
    </SafeAreaView>
  );
}

export default AuthenticationPage;
