import axios from 'axios';
import {
  DiscoveryDocument,
  makeRedirectUri,
  Prompt,
  ResponseType,
  useAuthRequest,
} from 'expo-auth-session';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import useAsync from 'react-use/lib/useAsync';

import { KakaoToken } from './types';
import useSessionStorage from './useSessionStorage';

// @ts-ignore
Constants.manifest.originalFullName = '@zucchini-morimori/app';

const discovery: DiscoveryDocument = {
  authorizationEndpoint: `${Constants.manifest?.extra?.kakaoOauthUrl}/authorize`,
  tokenEndpoint: `${Constants.manifest?.extra?.kakaoOauthUrl}/token`,
};

const useProxy = Platform.select({ web: false, default: true });

const getKakaoToken = async ({
  code,
  redirectUri,
  grantType = 'authorization_code',
}: {
  code: string;
  redirectUri: string;
  grantType?: 'authorization_code' | 'refresh_token';
}): Promise<KakaoToken | null> => {
  const url = `${Constants.manifest?.extra?.kakaoOauthUrl}/token`;

  try {
    const parameters = {
      grant_type: grantType,
      client_id: Constants.manifest?.extra?.kakaoClientId,
      redirect_uri: redirectUri,
      code,
    };

    const { data } = await axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      data: queryString.stringify(parameters),
      url,
    });

    console.log('TOKEN', data);
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getExpiresAt = (expiresIn: number): number => {
  return Date.now() + expiresIn * 100;
};

WebBrowser.maybeCompleteAuthSession();

type AuthStatus = 'success' | 'failure' | 'pending';

const redirectUri = makeRedirectUri({
  useProxy,
});

const useAuthentication = () => {
  const sessionStorage = useSessionStorage();

  const [authStatus, setAuthStatus] = useState<AuthStatus>('pending');

  console.log('REDIRECT_URI', redirectUri);
  const [request, response, promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.Code,
      clientId: Constants.manifest?.extra?.kakaoClientId,
      redirectUri,
      usePKCE: false,
    },
    discovery
  );

  useAsync(async () => {
    if (response?.type === 'success') {
      const { code } = response.params;

      const token = await getKakaoToken({
        code,
        redirectUri: request?.redirectUri as string,
      });

      if (!token) {
        setAuthStatus('failure');
        return;
      }

      await sessionStorage.setToken({
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        expiresAt: getExpiresAt(token.expires_in),
        jwt: token.id_token,
      });

      setAuthStatus('success');
    }
  }, [response, request]);

  const prompt = () => promptAsync({ useProxy });

  return { prompt, authStatus };
};

export default useAuthentication;
