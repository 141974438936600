import * as SecureStore from 'expo-secure-store';
import { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

export type SessionToken = {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
  jwt: string;
};

interface ISessionStorageService {
  setToken(token: SessionToken): Promise<void>;
  getToken(): Promise<SessionToken | null>;
}

interface StorageAdapter {
  setItemAsync(key: string, value: string): Promise<void>;
  getItemAsync(key: string): Promise<string | null>;
}

class SessionStorageService implements ISessionStorageService {
  static TOKEN_KEY = 'SESSION_TOKEN';

  constructor(private storage: StorageAdapter) {}

  async setToken(token: SessionToken): Promise<void> {
    const tokenStr = JSON.stringify(token);
    await this.storage.setItemAsync(SessionStorageService.TOKEN_KEY, tokenStr);
  }

  async getToken(): Promise<SessionToken | null> {
    const tokenRaw = await this.storage.getItemAsync(
      SessionStorageService.TOKEN_KEY
    );

    return JSON.parse(tokenRaw ?? 'null');
  }
}

class WebStorage implements StorageAdapter {
  constructor() {}

  setItemAsync(key: string, value: string): Promise<void> {
    return new Promise((resolve) => {
      window.localStorage.setItem(key, value);

      return resolve();
    });
  }

  getItemAsync(key: string): Promise<string | null> {
    return new Promise((resolve) => {
      const item = window.localStorage.getItem(key);

      return resolve(item);
    });
  }
}

const useSessionStorage = () => {
  const isNative = Platform.OS !== 'web';

  const storage = new SessionStorageService(
    isNative ? SecureStore : new WebStorage()
  );
  const serviceRef = useRef<ISessionStorageService>(storage);

  return serviceRef.current;
};

export default useSessionStorage;
